import React from 'react'
import './videoback.css'
import covervideo from "../../video/videofondo.mp4"

function Videoback() {
  return (
    <div className='videoback'>
      <video autoPlay loop muted className='video'>
      <source src={covervideo} type="video/mp4" />
      </video>
    </div>
  )
}

export default Videoback
