import React from 'react'
import './Servicios.css'
import {BsFillPatchCheckFill} from 'react-icons/bs'
import {BsTwitch} from 'react-icons/bs'
import {VscDebugBreakpointLog} from 'react-icons/vsc'
import {GrInstagram} from 'react-icons/gr'
import {SiJavascript} from 'react-icons/si'
import {IoLogoNodejs} from 'react-icons/io'
import {FaHtml5, FaServer} from 'react-icons/fa'
import {SiCss3} from 'react-icons/si'
import {FaReact} from 'react-icons/fa'
import {RiPagesFill} from 'react-icons/ri'
import {SiAdobepremierepro} from 'react-icons/si'
import {SiAdobephotoshop} from 'react-icons/si'
import {SiAdobelightroom} from 'react-icons/si'
import {MdOutlineVideoSettings} from 'react-icons/md'
import {MdAddBusiness} from 'react-icons/md'
import {SiDiscord} from 'react-icons/si'
import {SiAudiomack} from 'react-icons/si'
import {GiTechnoHeart} from 'react-icons/gi'
import {TbSocial} from 'react-icons/tb'


export default function Servicios() {
  return (
    <section id='servicios' className='servicios'>
      <br />
      <br />
      <br />
      <br />
      <h5>¿Cuáles son nuestros srevicios?</h5>
      <h2 className='colore'>Nuestros Servicios</h2>
    <div className="servicios">
    
      <div className="container experience__container">
      <div className="experience__backend">
        <h3>IMAGEN CORPORATIVA &nbsp; <MdAddBusiness/> </h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Tarjeta de presentación</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Tarjetas de identificación</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>infografías</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Nombre de empresa</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Hoja membretada</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Folletos</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Flyers</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Sublimación</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Diseño de Empaques</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Lonas</h4>
             
             </div>
             </article>


             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Branding Red Social</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Carteles</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Serigrafia</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Logo</h4>
             
             </div>
             </article>
             
             
           </div>
        </div>
{/*AQUI TERMINA Imagen corporativa*/}
   
        <div className="experience__backend">
        <h3>REDES SOCIALES &nbsp;  <TbSocial/></h3>
           <div className="experience__content">
            
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Branding</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Logo</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Bot de respuesta para chat</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Foto de perfil</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Foto de portada</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Banner</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Pieza gráfica publicidad</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Historias</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Post</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Manejo de redes</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Diseño de feed</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Bot follower</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Reels/TikTok/Short</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Historias destacadas</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Biografia</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Busqueda de hashtags</h4>
             
             </div>
             </article>

                        
             
             
           </div>
        </div>

        {/*AQUI TERMINA INSTA*/}
        <div className="experience__backend">
        <h3>DESARROLLO DE PÁGINAS WEB &nbsp; <RiPagesFill/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Re diseño de pagina</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Integración de bots</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Páginas estáticas</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Páginas dinámicas</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Páginas profesionales </h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Landing pages</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Mantenimiento de pagina web</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Integración de APIs</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Creación de deshboard</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Posicionamiento SEO</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Estudio de palabras clave </h4>
             
             </div>
             </article>
             
           <div className="logos">
           <SiJavascript/> <IoLogoNodejs/> <FaHtml5/> <SiCss3/> <FaReact/>
           </div>  
             
           </div>
        </div>

        {/*AQUI TERMINA LA PAGINA*/}
        <div className="experience__frontend">
           <h3>DISEÑOS PARA TWITCH &nbsp; <BsTwitch/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Banner</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Alertas</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Logo o pfp</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Paneles</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Animaciones</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Pantallas of Stream</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Overlays</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Escenas</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Intros y Outros</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Programación de integraciones en twitch</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Silenciar música con copyright del VOD</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Configuración bots para chat</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Configuración de comandos</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Configuración de extensiones</h4>
             
             </div>
             </article>
             
             </div>
           </div>

           {/*AQUI TERMINA EL TWITCH*/}
        <div className="experience__backend">
        <h3>EDICIÓN DE VIDEO &nbsp; <MdOutlineVideoSettings/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>TikToks/Reels/Short</h4>
             
             </div>
             </article>
             
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Edición video YouTube</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Grabación de Video Profesional</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Edición de video profesional</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Corrección de imagen</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Video publicitario</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Adaptación de video original a formato tiktok </h4>
             
             </div>
             </article>

             

            <div className="logos1">
           <SiAdobepremierepro/> <SiAdobephotoshop/> <SiAdobelightroom/>
           </div>  
             
             
             
           </div>
        </div>
         {/*AQUI TERMINA VIDEO*/}
        
  
        <div className="experience__backend">
        <h3>EDICIÓN DE AUDIO &nbsp; <SiAudiomack/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Edición de audio</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Mejora de audio</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Instalación de filtros para OBS </h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Modulación de voz</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Descifrado de modulación</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Supresión de ruido</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Mejora de altos y bajos</h4>
             
             </div>
             </article>
             
             
             
             
           </div>
        </div>
        {/*AQUI TERMINA LA SONIDO*/}

        <div className="experience__backend">
        <h3>DISCORD &nbsp; <SiDiscord/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Creación y configuración de servidores</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Configuración de servidor (bots, roles, comandos,canales) </h4>
             
             </div>
             </article>
             
             
             
             
             
           </div>
        </div>
        {/*AQUI TERMINA DISCORD*/}

   
<div className="experience__backend">
        <h3>SERVIDOR &nbsp; <FaServer/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Alojamiento de páginas web</h4>
             
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Alojamiento de proyectos</h4>
             
             </div>
             </article>
             
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Administración de servidores</h4>
             
             </div>
             </article>
             
           </div>
        </div>
        {/*AQUI TERMINA SERVIDOR*/}

           
        <div className="experience__backend">
        <h3>INSTAGRAM Y SOCIAL MEDIA &nbsp;  <GrInstagram/></h3>
           <div className="experience__content">
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Branding</h4>
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Historias Destacadas</small><br />
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Tus Hashtags Perfectos</small><br />
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Una buena Biografía</small>
             </div>
             </article>
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Creación de Posts</h4>
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Carrusel</small><br />
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Normal</small><br />
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Animación</small>
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Creación de Historias</h4>
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Carrusel</small><br />
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Normal</small><br />
             <small className='text-light'><VscDebugBreakpointLog/> &nbsp; Animación</small>
             </div>
             </article>
             
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Reels</h4>
             
             </div>
             </article>
    
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Pieza gráfica de publicidad </h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Creación de historias</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Manejo de redes</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Diseño de feed</h4>
             
             </div>
             </article>

             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Configuración de software con integraciones</h4>
             
             </div>
             </article>

                        
             
             
           </div>
        </div>

        {/*AQUI TERMINA INSTA*/}
        
        <div className="experience__backend">
        <h3>INTELIGENCIAS ARTIFICIALES &nbsp; <GiTechnoHeart/></h3>
           <div className="">
             
             <article className='experience__details'>
             <BsFillPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>PREGUNTA POR LOS SERVICIOS!!</h4>
             
             </div>
             </article>
             
             
             
             
             
           </div>
        </div>
        {/*AQUI TERMINA DISCORD*/}
        <br /><br /><br /><br />
        </div>
    </div>


        
    </section>
  )
}
