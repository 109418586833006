import React, { useEffect } from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AcercaDe from './components/AcercaDe/AcercaDe'
import Header from './components/header/Header'
import Header1 from './components/header1/Header1'
import Videoback from './components/video-back/videoback'
import Presenta from './components/presenta/Presenta'
import Servicios from './components/Servivios/Servicios'
/*import Potafolio from './components/Portafolio/Potafolio'*/
import Contacto from './components/Contacto/Contacto'
import Portafolio2 from './components/Portafolio2/Portafolio2'
import Footer from './components/footer/Footer'
import Footer1 from './components/footer1/Footer1'
import Cabecera from './components/Blog2/Cabecera/Cabecera'
import Card from './components/Blog2/Card/Card'
import Tendencias1 from './components/Blog2/Articulos/Tendencias1'
import Videoback1 from './components/video-back1/videoback1'
import Sitemap from "./components/SiteMap/Sitemap";
import Portafolio3 from "./components/Portafolio3/Portafolio3";


const App = () => {
  return (
<>
<Router>
  <div className="app">
   <Switch>

   <Route path="/sitemap">
      <Sitemap/>
   </Route>

   <Route path="/TendenciasDeDiseno">
      <Header1/>
      <Tendencias1/>
      <Videoback1/>
      <Footer1/>
   </Route>

   <Route path="/blog">
      <Header1/>
      <Card/>
      <Footer1/>
   </Route>
   
   <Route path="/">
      
      <Header/>
      <Presenta/>
      <Videoback/>
      <AcercaDe/>
      <Servicios/>
      {/*<Potafolio/>*/}
      
      <Portafolio3/>
      {/*<SwiperPor/>*/}
      <Portafolio2/>
      <Contacto/>
      <Footer/>
   </Route>

   


   </Switch>
  </div>
</Router>
</>
  )
}

export default App
