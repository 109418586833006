import React from 'react'
import './Presenta.css'
import CTA from '../presenta/CTA'

function Presenta() {
  return (
    <section className='presenta'>
    <div>
        <div className="container header__container">
       <div className="texto">
        
       <h5>Hola! Bienvenido A </h5>
       <h1 className='unu'>Succeding Media</h1>
       <h5 className='text-light'>Agencia Digital</h5>
       </div>
       <div className="cta1">
       <CTA />
       </div>
      
{/** 
      <div className="video">
        <video autoPlay loop muted className='videofondo'>
        <source src={Videofondo} type="video/mp4" />
      </video>
        </div>
*/}      
     </div>
    </div>
    </section>
  )
}

export default Presenta
