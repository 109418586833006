import React from 'react'
import './Contacto.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'


function Contacto() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_iygllec', 'template_csityma', form.current, 'uWkJdc9SYfPu9hezu')
    
    e.target.reset()
  };
  return (
    <section id='contacto' className='contacto'>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h5>Ponte en Contacto</h5>
      <h2 className='colore'>Contáctanos</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Correo</h4>
            <h5>succedingmediaacc@gmail.com</h5>
            <a href="mailto:succedingmediaacc@gmail.com" target="_blank">Enviar un correo</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Succeding Media</h5>
            <a href="https://m.me/106380454536037" target="_blank">Enviar un mensaje</a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>+524624209680</h5>
            <a href="https://api.whatsapp.com/send?phone=5214624209680&text=hola,%20vengo%20a pedir informes!!%20/SM" target="_blank">Envía un Whats</a>
          </article>
        </div>
        {/*FIN DE LA OPCION DE CONTACTO*/}
        <form ref={form} onSubmit={sendEmail}>
          <h4 className='Textareasobre'>Tu nombre completo</h4>
          <input type="text" name="name" id="" placeholder='Tu Nombre Completo' required />
          <h4 className='Textareasobre'>Tu email</h4>
          <input type="email" name="email" id="" placeholder='Tu Email' required />
          <h4 className='Textareasobre'>Tu Mensaje</h4>
          <textarea name="message" id="" rows="7" placeholder='Tu Mensaje' required></textarea>
           <button type='submit' className='btn btn-primary'>Envía tu Mensaje</button>
        </form>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  )
}

export default Contacto
