import React from 'react'
import './videoback1.css'
import covervideo1 from "../../video/videofondoart.mp4"

function Videoback1() {
  return (
    <div className='videoback1'>
      <video autoPlay  muted className='video1'>
      <source src={covervideo1} type="video/mp4" />
      </video>
    </div>
  )
}

export default Videoback1
