import './Portafolio2.css'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';


//PROYECTOS PRINCIPAL

import IMG1 from "../../assets/Nueva carpeta/1/angeles.jpg";
import IMG2 from "../../assets/Nueva carpeta/1/restaurn.jpg";
import IMG3 from "../../assets/Nueva carpeta/1/ecocare.jpg";
import IMG4 from "../../assets/Nueva carpeta/1/repartidores.jpg";
import IMG5 from "../../assets/Nueva carpeta/1/sinck.jpg";
import IMG6 from "../../assets/Nueva carpeta/ZPF2/astro.jpg";
import IMG7 from "../../assets/Nueva carpeta/ZPF2/twitchsinck.jpg";
import IMG8 from "../../assets/Nueva carpeta/ZPF2/granek.jpg";
import IMG9 from "../../assets/Nueva carpeta/ZPF2/ringfree.jpg";
import IMG10 from "../../assets/Nueva carpeta/ZPF2/zonacero.jpg";
import IMG11 from "../../assets/Nueva carpeta/ZPF2/esquiteriaestilodf.jpg";
import IMG12 from "../../assets/Nueva carpeta/ZPF2/gloriacano.jpg";
import IMG13 from "../../assets/Nueva carpeta/ZPF2/johanacuadros.jpg";
import IMG14 from "../../assets/Nueva carpeta/ZPF2/dulcesrecuerdos.jpg";
/*
autoplay={{
  delay: 2500,
  disableOnInteraction: false,
}}*/


function Portafolio2() {
  return (
    <section id='portafolio' className='portafolio'>
      <Swiper
       slidesPerView={'3'}
       centeredSlides={true}
       spaceBetween={0}
       autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
        pagination={{
          clickable: false,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
        
            <img  src={IMG1}/>


        </SwiperSlide>
        <SwiperSlide>

            <img  src={IMG2}/>

        

        </SwiperSlide>
        <SwiperSlide>


            <img  src={IMG3}/>
        

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG4} alt="" />
           

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG5} alt="" />
         

        </SwiperSlide>
        <SwiperSlide>

       
          <img  src={IMG6} alt="" />
          

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG7} alt="" />
       
        

        </SwiperSlide>
        <SwiperSlide>

        
          <img  src={IMG8} alt="" />
          

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG9} alt="" />
         
     

        </SwiperSlide>

        <SwiperSlide>

          <img  src={IMG10} alt="" />
         

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG11} alt="" />
         

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG12} alt="" />
         

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG13} alt="" />
         

        </SwiperSlide>
        <SwiperSlide>

          <img  src={IMG14} alt="" />
         

        </SwiperSlide>
         {/*<SwiperSlide>Slide 9</SwiperSlide> */}
      </Swiper>
    </section>
  )
}

export default Portafolio2
