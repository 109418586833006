import React from 'react'
import './AcercaDe.css'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

function AcercaDe() {
  return (
    <section id='acercade1' className='acercade1'>
        
      <div className="container about__container">
        <div className="about__me">
 {/*        <div className="about__me-image">
           <img src={ME} alt="Acerca de" />
         </div> 
  */}
        </div>

        <div className="about__content">
          
        <div className="about__cards space">
          <div className="acercade">
          <h5 className="spacetxt">Un poco</h5>
          <h2 className="spacetxt spacetxt1 colore">Acerca de Nosotros</h2>
          </div>
        
          <article className='about__card '>
          <FaAward className='about__icon' />
           <h5>Experiencia</h5>
           <small>+2 años de experiencia en proyectos personales</small>
          </article>  
          <article className='about__card '>
          <FiUsers className='about__icon' />
           <h5>Clientes</h5>
           <small>+10 clientes satisfechos</small>
          </article>
          <article className='about__card'>
          <VscFolderLibrary className='about__icon ' />
           <h5>Proyectos</h5>
           <small>+5 proyectos llevados a cabo</small>
          </article>
          
        </div>

        <p>
        Somos una agencia joven, versátil y enfocada en varias ramas del marketing digital. Con experiencia en la creación de piezas gráficas, manejo de redes sociales, edición y creación de video profesional, además de páginas web.  
        </p>
        <a href="#contacto" className='btn btn-primary'>Hablemos!</a>
        </div>
      </div>
    </section>
  )
}

export default AcercaDe
