import React from 'react'
import './Header.css'
import './Header1.css'
import {AiOutlineMenu} from 'react-icons/ai'
import LOGO from '../../assets/LogoSMBLANCO2.png'
import { useState } from 'react'
import Videofondo from "../../video/videofondo.mp4"

function Header() {
    const [activeNav, setActiveNav] = useState('#')
  return (
    
    
        <header>
          {/* */}
            <nav>
                <div className="logo">
                    <img src={LOGO} alt="" />
                </div>

                <input type="checkbox" id="check" />
                <label for="check" class="bar-btn">
                    <i class="fas fa-bars"><AiOutlineMenu/></i>
                </label>
                <ul class='nav-menu'>
                    <li><a class="active" href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>Home</a></li>
                    <li><a href="#acercade1" onClick={() => setActiveNav('#acercade1')} className={activeNav === '#acercade1' ? 'active' : ''}>Acerca de</a></li>
                    <li><a href="#servicios" onClick={() => setActiveNav('#servicios')} className={activeNav === '#servicios' ? 'active' : ''}>Servicios</a></li>
                    <li><a href="#portafolio" onClick={() => setActiveNav('#portafolio')} className={activeNav === '#portafolio' ? 'active' : ''}>Portafolio</a></li>
                    <li><a href="#contacto" onClick={() => setActiveNav('#contacto')} className={activeNav === '#contacto' ? 'active' : ''}>Contacto</a></li>
                    <li><a href="/blog" onClick={() => setActiveNav('/blog')} className={activeNav === '/blog' ? 'active' : ''}>BLOG</a></li>
                </ul>
            </nav>

        </header>
      
   
  )
}

export default Header