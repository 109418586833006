import React, { useState } from "react";
import "./Portafolio3.css";
import Modal1 from "./Modal1";
import styled from "styled-components";
import IMG1 from "../../assets/Nueva carpeta/ZPF2/SM.jpg";
import IMG2 from "../../assets/Nueva carpeta/ZPF2/coretech.jpg";
import IMG3 from "../../assets/Nueva carpeta/ZPF2/successfulsecrets.jpg";
import IMG4 from "../../assets/Nueva carpeta/ZPF2/xavpalacios.jpg";
import IMG5 from "../../assets/Nueva carpeta/ZPF2/twitchadez.jpg";
import IMG6 from "../../assets/Nueva carpeta/ZPF2/astro.jpg";
import IMG7 from "../../assets/Nueva carpeta/ZPF2/twitchsinck.jpg";
import IMG8 from "../../assets/Nueva carpeta/ZPF2/granek.jpg";
import IMG9 from "../../assets/Nueva carpeta/ZPF2/ringfree.jpg";
import IMG10 from "../../assets/Nueva carpeta/ZPF2/zonacero.jpg";
import IMG11 from "../../assets/Nueva carpeta/ZPF2/esquiteriaestilodf.jpg";
import IMG12 from "../../assets/Nueva carpeta/ZPF2/gloriacano.jpg";
import IMG13 from "../../assets/Nueva carpeta/ZPF2/johanacuadros.jpg";
import IMG14 from "../../assets/Nueva carpeta/ZPF2/dulcesrecuerdos.jpg";
import IMG15 from "../../assets/Nueva carpeta/ZPF2/parroquia.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "CORETECH",
    articlelink: "/TendenciasDeDiseno",
  },
  {
    id: 2,
    image: IMG2,
    title: "CORETECH",
    articlelink: "https://github.com/SinckCode/ProgramasEscuela.git",
  },
  {
    id: 3,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 4,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 5,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 6,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 7,
    image: IMG1,
    title: "CORETECH",
    articlelink: "/TendenciasDeDiseno",
  },
  {
    id: 8,
    image: IMG2,
    title: "CORETECH",
    articlelink: "https://github.com/SinckCode/ProgramasEscuela.git",
  },
  {
    id: 9,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 10,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 11,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
  {
    id: 12,
    image: IMG3,
    title: "CORETECH",
    articlelink: "https://portafolio-sm.web.app/",
  },
];

const Portafolio3 = () => {
  const [estadoModal1, cambiarEstadomodal1] = useState(false);
  const [estadoModal2, cambiarEstadomodal2] = useState(false);
  const [estadoModal3, cambiarEstadomodal3] = useState(false);
  const [estadoModal4, cambiarEstadomodal4] = useState(false);
  const [estadoModal5, cambiarEstadomodal5] = useState(false);
  const [estadoModal6, cambiarEstadomodal6] = useState(false);
  const [estadoModal7, cambiarEstadomodal7] = useState(false);
  const [estadoModal8, cambiarEstadomodal8] = useState(false);
  const [estadoModal9, cambiarEstadomodal9] = useState(false);
  const [estadoModal10, cambiarEstadomodal10] = useState(false);
  const [estadoModal11, cambiarEstadomodal11] = useState(false);
  const [estadoModal12, cambiarEstadomodal12] = useState(false);

  return (
    <section id="portafolio">
      <h5>Mira nuestros proyectos</h5>
      <h2>PORTAFOLIO</h2>

      {/*EDITAR LAS TARJETAS*/}

      {/*PRIMER TARJETA*/}
      <div className="container portfolio__container1">
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG1} alt="CORETECH" />
          </div>

          <h3 className="title1">
            SUCCEDING <br />
            MEDIA
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*SEGUNDA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG2} alt="CORETECH" />
          </div>

          <h3 className="title2">
            CORE TECH <br />
            <br />
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*TERCERA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG3} alt="CORETECH" />
          </div>

          <h3 className="title3">
            SUCCESSFUL
            <br /> SECRETS
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*CUARTA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG4} alt="CORETECH" />
          </div>

          <h3 className="title4">
            XAV <br />
            PALACIOS
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*QUINTA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG5} alt="CORETECH" />
          </div>

          <h3 className="title5">
            TWITCH <br />
            ADEZ
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*SEXTA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG6} alt="CORETECH" />
          </div>

          <h3 className="title6">ASTRO</h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*SEPTIMA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG7} alt="CORETECH" />
          </div>

          <h3 className="title7">TWITCH SINCK</h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*OCTABA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG8} alt="CORETECH" />
          </div>

          <h3 className="title8">GRANEK</h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*NOVENA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG9} alt="CORETECH" />
          </div>

          <h3 className="title9">RING FREE</h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*DECIMA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG10} alt="CORETECH" />
          </div>

          <h3 className="title10">ZONA CERO</h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*ONCEABA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG11} alt="CORETECH" />
          </div>

          <h3 className="title11">
            ESQUITERIA ESTILO <br /> DF
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*DOCEABA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG12} alt="CORETECH" />
          </div>

          <h3 className="title12">
            DOCTORA <br />
            GLORIA CANO
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*TERCERA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG13} alt="CORETECH" />
          </div>

          <h3 className="title13">JOHANA CUADROS COSMETÓLOGA</h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*CUARTA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG14} alt="CORETECH" />
          </div>

          <h3 className="title14">
            DULCES
            <br /> RECUERDOS
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>

        {/*CUARTA TARJETA*/}
        <article className="portfolio__item1">
          <div className="portfolio__item-image1">
            <img src={IMG15} alt="CORETECH" />
          </div>

          <h3 className="title14">
            JOVENES
            <br />SAN PEDRO APOSTOL
          </h3>

          <div className="portfolio__item-cta1">
            <div className="botonModal">
              <ContenedorBotones>
                <Boton
                  className="boton1"
                  onClick={() => cambiarEstadomodal1(!estadoModal1)}
                >
                  Ver más
                </Boton>
              </ContenedorBotones>
            </div>
          </div>
        </article>
        {/*------------------------*/}
      </div>
      

      {/*ESTILOS DE LOS MODALES PARA EDITARLOS 3*/}

      {/*MODAL 1*/}
      <Modal1
        estado={estadoModal1}
        cambiarEstado={cambiarEstadomodal1}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 2*/}

      <Modal1
        estado={estadoModal2}
        cambiarEstado={cambiarEstadomodal2}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 3*/}

      <Modal1
        estado={estadoModal3}
        cambiarEstado={cambiarEstadomodal3}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 4*/}

      <Modal1
        estado={estadoModal4}
        cambiarEstado={cambiarEstadomodal4}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 5*/}

      <Modal1
        estado={estadoModal5}
        cambiarEstado={cambiarEstadomodal5}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 6*/}

      <Modal1
        estado={estadoModal6}
        cambiarEstado={cambiarEstadomodal6}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 7*/}

      <Modal1
        estado={estadoModal7}
        cambiarEstado={cambiarEstadomodal7}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 8*/}

      <Modal1
        estado={estadoModal8}
        cambiarEstado={cambiarEstadomodal8}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 9*/}

      <Modal1
        estado={estadoModal9}
        cambiarEstado={cambiarEstadomodal9}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 10*/}

      <Modal1
        estado={estadoModal10}
        cambiarEstado={cambiarEstadomodal10}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 11*/}

      <Modal1
        estado={estadoModal11}
        cambiarEstado={cambiarEstadomodal11}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*MODAL 12*/}

      <Modal1
        estado={estadoModal12}
        cambiarEstado={cambiarEstadomodal12}
        titulo="Titulo"
        mostrarHeader={true}
        mostrarOverlay={true}
        posicionModal={"center"}
        padding={"40px"}
      >
        <Contenido>
          <h1>Ventana Modal</h1>
          <p>Reutilizable y con opciones de personalizacion</p>
          <Boton onClick={() => cambiarEstadomodal1(!estadoModal1)}>
            Aceptar
          </Boton>
        </Contenido>
      </Modal1>

      {/*AQUI EMPIEZA LA SIGUIENTE CARTA DEL PORTAFOLIO */}
    </section>
  );
};

export default Portafolio3;

const ContenedorBotones = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  left: 0rem;
  text-align: center;
`;

const Boton = styled.button`
  display: block;
  padding: 10px 30px;
  border-radius: 100px;
  color: var(--color-bg-variant);
  border: none;
  background: #fff;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transition: 0.3s ease all;

  &:hover {
    background: var(--color-bg-variant);
    color: #fff;
  }
`;

const Contenido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    color: black;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
    color: black;
  }

  img {
    width: 100%;
    vertical-align: top;
    border-radius: 3px;
  }
`;
