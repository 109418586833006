import React from 'react'

function Sitemap() {
  return (
    <div>

-<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">


-<url>

<loc>https://succedingmedia.com/</loc>

<lastmod>2023-06-25T08:36:02+01:00</lastmod>

<priority>0.6</priority>

</url>


-<url>

<loc>https://succedingmedia.com/blog</loc>

<lastmod>2023-06-25T08:36:02+01:00</lastmod>

<priority>1.0</priority>

</url>


-<url>

<loc>https://succedingmedia.com/TendenciasDeDiseno</loc>

<lastmod>2023-06-25T08:36:02+01:00</lastmod>

<priority>1.0</priority>

</url>

</urlset>
    </div>
  )
}

export default Sitemap
