import React from 'react'
import PAQUETES from '../../assets/paquetes.pdf'
const CTA = () => {
  return (
    <div className="espa">
    <div className='cta'>
      <a href={PAQUETES} download className='btn separa'>⠀⠀Paquetes⠀⠀</a>
      <a href="#contacto" className='btn btn-primary'>Hablemos!</a>
    </div>
    </div>
    
  )
}

export default CTA
