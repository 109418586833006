import React from 'react'
import './Card.css'
import IMG1 from '../../../assets/Blog/Blog/pexels-mikechie-esparagoza-1601773.jpg'
import IMG2 from '../../../assets/Blog/Blog/pexels-ovan-57690.jpg'
import IMG3 from '../../../assets/Blog/Blog/pexels-pixabay-39284.jpg'
import IMG4 from '../../../assets/Blog/portfolio4.jpg'
import IMG5 from '../../../assets/Blog/portfolio5.png'
import IMG6 from '../../../assets/Blog/portfolio6.jpg'
const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Tendencias de diseño gráfico para el 2023',
    articlelink: '/TendenciasDeDiseno',
    descripcion: 'En este artículo, exploraremos las últimas tendencias en diseño gráfico que están dominando el panorama en el año 2023. '
  },
  {
    id: 2,
    image: IMG2,
    title: 'Cómo elegir el mejor logotipo para tu marca',
    articlelink: 'https://github.com/SinckCode/ProgramasEscuela.git',
    descripcion: 'El logotipo es un elemento crucial para la identidad de tu marca. En este artículo, te guiaré a través de los aspectos más importantes a considerar al elegir un logotipo para tu negocio.'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Diseño web responsive: Cómo optimizar la experiencia del usuario en todos los dispositivos',
    articlelink: 'https://portafolio-sm.web.app/',
    descripcion: 'En el mundo digital actual, es fundamental que tu sitio web sea responsive, es decir, que se adapte a diferentes dispositivos y tamaños de pantalla. En este artículo, exploraremos los principios clave del diseño web responsive y cómo implementarlos en tu sitio. '
  },
  
]


const Card = () => {
  return (
    <section id='portafolio'>
      <h5>Mira nuestros articulos</h5>
      <h2>BLOG</h2>

      <div className="container portfolio__container">
      {
        data.map(({id, image, title, github, articlelink, descripcion}) =>{
          return(
            <article key={id} className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={image} alt={{title}} />
          </div>
          
          <h3 className='title'>{title}</h3>
          <div className="descripcionn">{descripcion}</div>
            <div className="portfolio__item-cta">
            
            <a href={articlelink} className='btn btn-primary' target='_blank'>Ver el Articulo</a>
            
            
            </div>
        </article>
          )
        })
      }      
    
      </div>
    </section>
  )
}

export default Card
