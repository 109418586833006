import React from 'react'
import './Footer1.css'
import {BsInstagram} from 'react-icons/bs'
import {AiOutlineGithub} from 'react-icons/ai'
import {BiWorld} from 'react-icons/bi'
import {BsFacebook} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>SUCCEDING MEDIA</a>
      <ul className='permalinks'>
        <li><a href="/">Home</a></li>
        <li><a href="/#acerdade1">Acerca De</a></li>
        <li><a href="/#servicios">Servicios</a></li>

        <li><a href="/#portfolio">Portafolio</a></li>
        
        <li><a href="/#contact">Contacto</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.instagram.com/succeding.media/"><BsInstagram/></a>
        
        <a href="https://www.facebook.com/Succeding-Media-106279672179195"><BsFacebook/></a>
      </div>

      <div className="footer__copyright">
        <small>
          &copy; Propiedad de Succeding Media's Company. Todos los derechos reservados.
        </small>
      </div>
    </footer>
  )
}

export default Footer